<template>
  <div class="suggestions">
    <transition name="fade-slide">
      <div v-if="shouldShow" class="suggestions__box">
        <ul class="suggestions__list">
          <li
            v-for="(suggestion, index) in suggestions"
            :key="suggestion.id"
            class="suggestions__item"
          >
            <router-link
              class="suggestions__product"
              :to="{
                query: {
                  mode: 'details',
                  productId: suggestion.productId,
                  variantId: suggestion.id,
                },
              }"
              @click.native="trackProductClick(suggestion, index)"
            >
              <div
                v-if="
                  suggestion.images &&
                    suggestion.images[0] &&
                    suggestion.images[0].thumbnail
                "
                class="suggestions__image-container"
              >
                <img
                  :src="suggestion.images[0].thumbnail.src"
                  :alt="suggestion.images[0].thumbnail.alt"
                  class="suggestions__image"
                  decoding="async"
                  loading="lazy"
                />
              </div>
              <div class="suggestions__text">
                <Stack :quarter="true">
                  <h1 class="suggestions__title">
                    {{ suggestion.productName }} -
                    {{ suggestion.variantName }}
                  </h1>
                </Stack>
                <Stack :quarter="true">
                  <InformationList :information="suggestion" />
                </Stack>
              </div>
            </router-link>
          </li>
          <li v-if="!isOnProductsPage">
            <router-link
              :to="{ name: 'products' }"
              class="suggestions__show-all"
            >
              {{ getDictionaryEntry("Common.ShowMore") }}
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
    <div v-if="shouldShow" class="suggestions__underlay" @click="close"></div>
  </div>
</template>

<script>
import InformationList from "@/components/InformationList";
import Stack from "@/components/Stack";

export default {
  name: "SearchSuggestions",
  components: {
    InformationList,
    Stack,
  },
  props: {
    close: {
      required: true,
      type: Function,
    },
    suggestions: {
      required: true,
      type: Array,
    },
    trackProductClick: {
      required: true,
      type: Function,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isOnProductsPage() {
      return this.$route.name === "products";
    },
    shouldShow() {
      return this.suggestions && this.suggestions.length && this.visible;
    },
  },
  created() {
    const handleEscape = event => {
      if (this.visible && event.keyCode === 27) {
        this.close();
      }
    };

    document.addEventListener("keydown", handleEscape);

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", handleEscape);
    });
  },
};
</script>

<style lang="scss" scoped>
.suggestions {
  color: var(--color-text-primary);
  left: 0;
  position: absolute;
  right: 0;

  &__box {
    background-color: var(--color-card-background);
    border-radius: 0.25rem;
    box-shadow: var(--elevation-8);
    left: 0;
    margin-top: 0.25rem;
    max-height: 75vh;
    overflow-y: auto;
    position: absolute;
    right: 0;
    z-index: 2;
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      background-color: var(--color-products-image-background);
      flex: 0 0 2.5rem;
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-top: calc(1 / 1) * 100%;
        width: 100%;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    }
  }

  &__list {
    @include reset-list();
  }

  &__product {
    align-items: flex-start;
    color: inherit;
    display: flex;
    flex-flow: row wrap;
    font-size: 0.875rem;
    font-weight: normal;
    justify-content: space-between;
    padding: var(--spacing-container);
    text-decoration: none;

    @media (min-width: 30rem) {
      flex-flow: nowrap;
    }
  }

  &__show-all {
    color: inherit;
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    padding: var(--spacing-container);
    text-align: center;
    text-decoration: none;
  }

  &__text {
    flex-basis: calc(100% - 2.5rem - var(--spacing-container));
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
  }

  &__title {
    font-size: 1rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__underlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
