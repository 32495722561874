<template>
  <ol class="size-selector__list">
    <li
      v-for="size in sizes"
      :key="size.sku"
      :class="{
        'size-selector__item--external-product':
          size.inventory && size.inventory.isExternalProduct,
        'size-selector__item--selected': size === selectedSize,
        'size-selector__item--selected--quick-buy':
          endCustomerQuickBuyMode && size === selectedSize,
      }"
      class="size-selector__item"
    >
      <button
        class="size-selector__item-action"
        @click="$emit('select-size', size)"
      >
        <div class="size-selector__size">
          {{ getPreferredSizeChartValue(size) }}
        </div>
        <div v-if="size.inventory" class="size-selector__stock">
          <StockIndicator
            v-if="size && size.inventory"
            :indicator-only="true"
            :inventory="size.inventory"
            :ignore-show-stock="endCustomerQuickBuyMode"
          />

          <span v-if="size === selectedSize">
            <svg
              v-if="
                size.inventory.allowNegativeStock ||
                  size.inventory.stock !== null
              "
              xmlns="http://www.w3.org/2000/svg"
              class="size-selector__stock-icon"
              viewBox="0 0 24 24"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="size-selector__stock-icon"
              viewBox="0 0 24 24"
            >
              <path d="M18 6 6 18M6 6l12 12" />
            </svg>
          </span>
        </div>
      </button>
    </li>
  </ol>
</template>

<script>
import StockIndicator from "@/components/StockIndicator";

export default {
  name: "SizeSelectorList",
  components: { StockIndicator },
  props: {
    selectedSize: {
      type: Object,
      default: null,
    },
    sizes: {
      required: true,
      type: Array,
    },
    endCustomerQuickBuyMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  &__item {
    position: relative;
    border-radius: 1rem;
    transition: background-color 300ms ease;

    &::before {
      background-color: var(--color-product-selected-size-background);
      border-radius: 2rem;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 300ms ease, opacity 300ms ease;
      z-index: -1;
    }

    &--selected {
      &--quick-buy {
        background-color: var(--color-product-selected-size-background);
      }

      &::before {
        opacity: 1;
      }
    }

    &-action {
      @include reset-button();

      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      width: 100%;

      &--no-padding {
        padding: 1rem 0;
      }

      .size-selector__item--out-of-stock & {
        cursor: not-allowed;
      }
    }
  }

  &__list {
    @include reset-list();
  }

  &__selected {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
  }

  &__size {
    font-weight: 700;

    .size-selector__item--out-of-stock & {
      font-weight: 400;
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  &__size-guide {
    display: flex;
    justify-content: flex-end;

    &-link {
      color: inherit;
      font-size: 0.9375rem;
    }
  }

  &__stock {
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    font-size: 0.8125rem;
    margin-left: var(--size-product-stock-icon);
    text-transform: lowercase;
    white-space: nowrap;

    &-icon {
      fill: none;
      height: var(--size-product-stock-icon);
      margin-left: calc(var(--size-product-stock-icon) * 0.75);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3;
      width: var(--size-product-stock-icon);

      * {
        stroke: var(--color-text-primary);
      }
    }
  }

  &__title {
    flex: 1 1 auto;
    margin-right: var(--spacing-stack);
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__underlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
