import axios from "axios";
import router from "@/router";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentOrder: null,
  currentOrderId: null,
  currentPage: 0,
  currentReceipt: null,
  errors: null,
  isModalForOrderErrorsOpen: false,
  filters: {},
  lastSearchedUserId: null,
  numberOfOrdersForApproval: 0,
  ordersForCustomerToManage: [],
  ordersForUserToManage: [],
  ordersOfUser: [],
  pagination: {},
  shouldShowAdministrativePrices: true,
  status: "",
  statusForOrdersForApproval: "", // TODO: When we need to link to a specific page when clicking the notification we probably need to use this for "pre-setting" the filter in the search
  totalNumberOfOrders: 0,
});

const getters = {
  currentOrder: state => state.currentOrder,
  currentOrderId: state => state.currentOrderId,
  currentOrderNumber: state => {
    if (!state.currentOrder) return null;

    return state.currentOrder.orderNumber;
  },
  currentPage: state => {
    if (!state.pagination) return 0;

    return state.pagination.currentPage || 0;
  },
  currentReceipt: state => state.currentReceipt,
  errors: state => state.errors,
  filters: state => state.filters,
  isModalForOrderErrorsOpen: state => state.isModalForOrderErrorsOpen,
  lastSearchedUserId: state => state.lastSearchedUserId,
  numberOfOrdersForApproval: state => state.numberOfOrdersForApproval,
  ordersForCustomerToManage: state => state.ordersForCustomerToManage || [],
  ordersForUserToManage: state => state.ordersForUserToManage || [],
  ordersOfUser: state => state.ordersOfUser || [],
  pagination: state => state.pagination,
  shouldShowAdministrativePrices: state => state.shouldShowAdministrativePrices,
  statusOfOrders: state => state.status,
  totalNumberOfOrders: state => state.totalNumberOfOrders,
  totalNumberOfOrdersForCustomerToManage: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
  totalNumberOfOrdersForUserToManage: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
};

const actions = {
  ACTIVATE_ADMINISTRATIVE_PRICES({ commit }) {
    commit("SET_STATE_OF_ADMINISTRATIVE_PRICES", { value: true });
  },
  APPROVE_ORDERS({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { identifiers } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/approve-orders`, {
        identifiers,
      })
      .then(({ data }) => {
        if (!data[0].success) throw { detail: data[0].reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId: identifiers[0] });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  CLOSE_MODAL_FOR_ORDER_ERRORS({ commit, dispatch, getters }) {
    commit("SET_STATE_OF_MODAL_FOR_ORDER_ERRORS", { value: false });

    dispatch("GET_ORDER_TO_MANAGE", { orderId: getters.currentOrderId });
  },
  DEACTIVATE_ADMINISTRATIVE_PRICES({ commit }) {
    commit("SET_STATE_OF_ADMINISTRATIVE_PRICES", { value: false });
  },
  EDIT_ORDER({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/orders/${payload.id}/edit`)
      .then(() => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });

        dispatch("authentication/GET_USER_CONFIGURATION", {}, { root: true });
        dispatch("basket/GET_BASKET", {}, { root: true });

        router.push({ name: "products" });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });

        if (error.response && error.response.status === 409) {
          commit("SET_ORDER_ERRORS", {
            errors: error.response.data,
          });
          dispatch("OPEN_MODAL_FOR_ORDER_ERRORS");
          return;
        }

        errorAlert(error);
      });
  },
  GET_NUMBER_OF_ORDERS_FOR_APPROVAL({ commit }) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/orders/count-for-approval`)
      .then(({ data }) => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_NUMBER_OF_ORDERS_FOR_APPROVAL", {
          number: data.totalCount,
        });
        commit("SET_STATUS_FOR_ORDERS_FOR_APPROVAL", { status: data.status });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ORDER({ commit }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    commit("SET_ORDER_ERRORS", {
      errors: null,
    });

    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.ECOMMERCE}/orders/${payload.orderId}`)
        .then(({ data }) => {
          commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENT_ORDER", { order: data });
          resolve();
        })
        .catch(error => {
          commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_ORDER_TO_MANAGE({ commit }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    const orderUrl = `${endpoints.ECOMMERCE}/order-management/orders/v2/${payload.orderId}`;

    const url = payload.userId ? `${orderUrl}/${payload.userId}` : orderUrl;

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(({ data }) => {
          commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENT_ORDER", { order: data });
          resolve();
        })
        .catch(error => {
          commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_ORDERS_FOR_CUSTOMER_TO_MANAGE({ commit }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });
    commit("SET_ORDERS_FOR_CUSTOMER_TO_MANAGE", { orders: [] });

    const {
      booleanFilterParams,
      facetFilterParams,
      createDateFrom,
      createDateTo,
      customerIdFilter,
      pageNumberZeroBased,
      pageSize,
      searchPhrase,
    } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/orders/search`, {
        booleanFilterParams,
        facetFilterParams,
        createDateFrom,
        createDateTo,
        customerIdFilter,
        pageNumberZeroBased,
        pageSize,
        searchPhrase,
      })
      .then(({ data: { documents, filters, pagination } }) => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters });
        commit("SET_ORDERS_FOR_CUSTOMER_TO_MANAGE", { orders: documents });
        commit("SET_PAGINATION", { pagination });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ORDERS_FOR_USER_TO_MANAGE({ commit, getters, rootGetters }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    const {
      booleanFilterParams,
      createDateFrom,
      createDateTo,
      facetFilterParams,
      pageNumberZeroBased,
      pageSize,
      searchPhrase,
      userIdFilter,
    } = payload;

    if (getters.lastSearchedUserId !== userIdFilter) {
      commit("SET_ORDERS_OF_USER", { orders: [] });
    }

    const isUserSalesperson = rootGetters["authentication/isUserSalesperson"];

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/orders/search`, {
        booleanFilterParams,
        createDateFrom,
        createDateTo,
        facetFilterParams,
        pageNumberZeroBased,
        pageSize,
        searchPhrase,
        userIdFilter,
      })
      .then(({ data: { documents, filters, pagination } }) => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters });
        commit("SET_ORDERS_FOR_USER_TO_MANAGE", { orders: documents });
        commit("SET_PAGINATION", { pagination });
      })
      .catch(error => {
        //It will return 404 for salespersons without any customers
        if (isUserSalesperson && error.response.status === 404) {
          commit("SET_ORDERS_STATUS", {
            status: statuses.success,
          });

          return;
        }

        commit("SET_ORDERS_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_ORDERS_OF_USER({ commit, getters }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    const {
      pageNumberZeroBased,
      pageSize,
      searchPhrase,
      userIdFilter,
    } = payload;

    if (getters.lastSearchedUserId !== userIdFilter) {
      commit("SET_ORDERS_OF_USER", { orders: [] });
    }

    return axios
      .post(`${endpoints.ECOMMERCE}/orders`, {
        pageNumberZeroBased,
        pageSize,
        searchPhrase,
        userIdFilter,
      })
      .then(({ data }) => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_ORDERS_OF_USER", {
          orders: data.orders,
          page: pageNumberZeroBased,
          totalCount: data.totalCount,
          userId: userIdFilter,
        });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_RECEIPT({ commit }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/orders/receipt/${payload.orderNumber}`)
      .then(({ data }) => {
        commit("SET_CURRENT_RECEIPT", { receipt: data });
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_TRACKING_INFORMATION_FOR_ORDER({ commit, getters }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.LOADING });

    const currentOrder = getters["currentOrder"];

    return axios
      .get(`${endpoints.ECOMMERCE}/orders/${payload.orderId}/tracking`)
      .then(({ data }) => {
        commit("SET_CURRENT_ORDER", {
          order: { ...currentOrder, tracking: data },
        });
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        //Service returns 404 if there is no available tracking information
        if (error.response.status === 404) {
          commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
          return;
        }
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  IMPORT_ORDER_FROM_ENGEL({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const orderId = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/order-management/orders/${orderId}/importengel`,
      )
      .then(() => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_TRACKING_INFORMATION_FOR_ORDER", { orderId });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_ORDER_ERRORS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ORDER_ERRORS", { value: true });
  },
  OVERRIDE_ORDER({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { comment, orderId, reference, requisition, isEndCustomer } = payload;

    const updateValues = isEndCustomer
      ? "updateEndCustomerValues"
      : "updateDealerValues";

    return axios
      .post(
        `${endpoints.ECOMMERCE}/order-management/orders/${orderId}/${updateValues}`,
        {
          comment,
          reference,
          requisition,
        },
      )
      .then(() => {
        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REJECT_ORDERS({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { comment, identifiers } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/reject-orders`, {
        comment,
        identifiers,
      })
      .then(({ data }) => {
        if (!data[0].success) throw { detail: data[0].reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId: identifiers[0] });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REOPEN_ORDERS({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { identifiers } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/reopen-orders`, {
        identifiers,
      })
      .then(({ data }) => {
        if (!data[0].success) throw { detail: data[0].reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId: identifiers[0] });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_CURRENT_ORDER({ commit }) {
    commit("SET_CURRENT_ORDER", { order: null });
    commit("SET_CURRENT_ORDER_ID", { orderId: null });
  },
  RESET_RECEIPT({ commit }) {
    commit("SET_CURRENT_RECEIPT", { receipt: null });
  },
  RESET_FILTERS({ commit }) {
    commit("SET_FILTERS", { filters: null });
  },
  REVERT_SHIPMENT_FROM_OWN_INVENTORY({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { orderId, orderLineId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/order-management/revertShipFromOwnInventory?orderId=${orderId}`,
        {
          lineId: orderLineId,
        },
      )
      .then(({ data }) => {
        if (!data.success) throw { detail: data.reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  SHIP_ALL_FROM_OWN_INVENTORY({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { orderId } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/order-management/shipAllFromOwnInventory`, {
        orderId,
      })
      .then(({ data }) => {
        if (!data.success) throw { detail: data.reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_CURRENT_ORDER_ID({ commit }, payload) {
    commit("SET_CURRENT_ORDER_ID", payload);
  },
  UPDATE_SHIPMENT_FROM_OWN_INVENTORY({ commit, dispatch }, payload) {
    commit("SET_ORDERS_STATUS", { status: statuses.SAVING });

    const { orderId, orderLineId, quantity } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/order-management/shipFromOwnInventory?orderId=${orderId}`,
        {
          lineId: orderLineId,
          quantity,
        },
      )
      .then(({ data }) => {
        if (!data.success) throw { detail: data.reason, status: 409 };

        commit("SET_ORDERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDER_TO_MANAGE", { orderId });
      })
      .catch(error => {
        commit("SET_ORDERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_CURRENT_ORDER(state, payload) {
    state.currentOrder = payload.order;
  },
  SET_CURRENT_ORDER_ID(state, payload) {
    state.currentOrderId = payload.orderId;
  },
  SET_CURRENT_RECEIPT(state, payload) {
    state.currentReceipt = payload.receipt;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload.filters;
  },
  SET_NUMBER_OF_ORDERS_FOR_APPROVAL(state, payload) {
    state.numberOfOrdersForApproval = payload.number;
  },
  SET_ORDERS_FOR_CUSTOMER_TO_MANAGE(state, payload) {
    state.ordersForCustomerToManage = payload.orders;
  },
  SET_ORDER_ERRORS(state, payload) {
    state.errors = payload.errors;
  },
  SET_ORDERS_FOR_USER_TO_MANAGE(state, payload) {
    state.ordersForUserToManage = payload.orders;
  },
  SET_ORDERS_OF_USER(state, payload) {
    state.currentPage = payload.page;
    state.lastSearchedUserId = payload.userId;
    state.ordersOfUser = payload.orders;
    state.totalNumberOfOrders = payload.totalCount;
  },
  SET_ORDERS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_STATE_OF_ADMINISTRATIVE_PRICES(state, payload) {
    state.shouldShowAdministrativePrices = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_ORDER_ERRORS(state, payload) {
    state.isModalForOrderErrorsOpen = payload.value;
  },
  SET_STATUS_FOR_ORDERS_FOR_APPROVAL(state, payload) {
    state.statusForOrdersForApproval = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
