<template>
  <div class="size-selector">
    <Stack :half="true">
      <Button
        :no-shadow="true"
        :round="true"
        :secondary="true"
        :show-arrow="true"
        :show-user="true"
        @click="openSelector"
      >
        <span class="size-selector__button-text">
          {{ getDictionaryEntry("ShopTo.SelectEmployees") }}
        </span>
      </Button>
    </Stack>
    <transition name="fade">
      <div v-if="isOpen" class="size-selector__box">
        <div class="product-details__content size-selector__box-content">
          <Stack>
            <div class="size-selector__header">
              <h2 class="product-details__title size-selector__title">
                {{
                  interpolateString(
                    getDictionaryEntry(
                      "ShopTo.SelectorTitleWithNumberOfAvailableEmployees",
                    ),
                    ["number"],
                    [filteredUsersToShopTo.length],
                  )
                }}
              </h2>
              <button
                class="size-selector__close"
                :aria-label="getDictionaryEntry('Common.Close')"
                @click="closeSelector"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 21"
                  class="size-selector__close-icon"
                >
                  <defs />
                  <g>
                    <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                  </g>
                </svg>
              </button>
            </div>
          </Stack>
          <Stack :one-and-half="true">
            <Button
              :buy="true"
              :disabled="shouldDisableButton"
              :round="true"
              :show-loader="isUpdatingBasket"
              @click="addToBasket"
              ><span v-if="totalQuantityOfTemporaryBasket">{{
                interpolateString(
                  getDictionaryEntry("ShopTo.AddNumberOfPiecesToBasket"),
                  ["number"],
                  [totalQuantityOfTemporaryBasket],
                )
              }}</span
              ><span v-else>{{
                getDictionaryEntry("Product.AddToBasket")
              }}</span></Button
            >
          </Stack>
          <Stack :half="true">
            <Input
              v-model="searchQuery"
              :hide-label="true"
              :label="getDictionaryEntry('Common.Labels.Search')"
              :placeholder="
                getDictionaryEntry('Common.Placeholders.SearchEmployee')
              "
              :round="true"
              type="search"
            />
          </Stack>
          <Stack :double="true">
            <ul class="employees">
              <li
                v-for="user in filteredUsersToShopTo"
                :key="user.userId"
                :class="{
                  'employee--active': activeUser === user.userId,
                  'employee--hovered': hoveredUser === user.userId,
                }"
                class="employee"
                @mouseenter="handleMouseEnter(user.userId)"
                @mouseleave="handleMouseLeave(user.userId)"
              >
                <div
                  v-if="temporaryBasket[user.userId]"
                  class="employee__blocks"
                >
                  <div class="employee__block">
                    <button class="employee__toggle" @click="toggleInformation">
                      <h3 class="employee__name" :title="user.name">
                        {{ user.name }}
                      </h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="employee__icon"
                        :class="{
                          'employee__icon--active':
                            isInformationOpen && activeUser === user.userId,
                        }"
                        viewBox="0 0 24 24"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>
                    <div class="employee__identifiers">
                      {{ user.employeeNumber }}
                      <span v-if="user.employeeNumber && user.userGroupName">
                        -
                      </span>
                      {{ user.userGroupName }}
                      <span
                        :class="{
                          'wallet-error': !hasWalletBalance(user.userId),
                        }"
                      >
                        ({{ getRemainingWallet(user.userId) }})
                      </span>
                    </div>
                  </div>
                  <transition mode="out-in" name="fade">
                    <div
                      v-if="activeUser === user.userId"
                      class="employee__block"
                    >
                      <div class="sizes">
                        <button class="sizes__toggle" @click="toggleSizes">
                          <StockIndicator
                            :ignore-show-stock="true"
                            :indicator-only="true"
                            :inventory="
                              temporaryBasket[user.userId].size.inventory
                            "
                            class="indicator"
                          />
                          <span class="sizes__label">
                            {{
                              getDictionaryEntry(
                                "Common.Labels.SizeAbbreviation",
                              )
                            }}
                          </span>
                          {{
                            getPreferredSizeChartValue(
                              temporaryBasket[user.userId].size,
                            )
                          }}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="sizes__icon"
                            :class="{
                              'sizes__icon--active':
                                isSizesOpen && activeUser === user.userId,
                            }"
                            viewBox="0 0 24 24"
                          >
                            <path d="m6 9 6 6 6-6" />
                          </svg>
                        </button>
                        <div
                          :id="`sizes-${user.userId}`"
                          ref="sizes"
                          class="sizes__content"
                        >
                          <ol class="sizes__list">
                            <li
                              v-for="size in sizes"
                              :key="`${size.sku}-${user.userId}`"
                              class="size"
                            >
                              <button
                                class="size__action"
                                @click="selectSize({ id: user.userId, size })"
                              >
                                <StockIndicator
                                  :ignore-show-stock="true"
                                  :indicator-only="true"
                                  :inventory="size.inventory"
                                  class="indicator"
                                />
                                <span class="size__label">{{
                                  getDictionaryEntry(
                                    "Common.Labels.SizeAbbreviation",
                                  )
                                }}</span>
                                {{ getPreferredSizeChartValue(size) }}
                              </button>
                            </li>
                          </ol>
                        </div>
                        <div
                          v-if="isSizesOpen && activeUser === user.userId"
                          class="sizes__underlay"
                          @click="closeSizes"
                        ></div>
                      </div>
                      <Counter
                        :initial-value="temporaryBasket[user.userId].quantity"
                        @quantity-changed="
                          updateQuantity({ id: user.userId, value: $event })
                        "
                      />
                    </div>
                    <div v-else-if="hoveredUser === user.userId">
                      <button
                        class="employee__trigger"
                        @click="activateUser(user.userId)"
                      ></button>
                      <div class="employee__indicator-container">
                        <span class="employee__indicator-text">
                          {{
                            getDictionaryEntry(
                              "ShopTo.SelectSizeAndQuantityAbbreviated",
                            )
                          }}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="employee__indicator"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="9" cy="21" r="1" />
                          <circle cx="20" cy="21" r="1" />
                          <path
                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-else
                      class="employee__block employee__block--quantity"
                    >
                      <div
                        v-if="temporaryBasket[user.userId].quantity"
                        class="employee__summary"
                      >
                        {{ temporaryBasket[user.userId].quantity }} x
                        {{
                          getDictionaryEntry(
                            "Common.Labels.SizeAbbreviation",
                          ).toLowerCase()
                        }}
                        {{
                          getPreferredSizeChartValue(
                            temporaryBasket[user.userId].size,
                          )
                        }}
                      </div>
                    </div>
                  </transition>
                </div>
                <div
                  :id="`information-${user.userId}`"
                  ref="information"
                  class="employee__content"
                >
                  <ul class="employee__information">
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.WalletBalance") }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ formatPriceCurrency(user.walletBalance ?? 0) }}
                        {{ user.currency }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{
                          getDictionaryEntry(
                            "Common.Labels.WalletRemainingBalance",
                          )
                        }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div
                        class="employee__information-title"
                        :class="{
                          'wallet-error': !hasWalletBalance(user.userId),
                        }"
                      >
                        {{ getRemainingWallet(user.userId) }}
                      </div>
                    </li>

                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.TopPartSize") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.topPartSize }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.Waist") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.waist }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.TrousersSize") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.trousersSize }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.Inseam") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.inseam }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.Neck") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.neck }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.OtherSizes") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.otherSize }}
                      </div>
                    </li>
                    <li class="employee__information-block">
                      <div class="employee__information-title">
                        {{ getDictionaryEntry("Common.Labels.Chest") }}
                      </div>
                      <div class="employee__information-value">
                        {{ user.chest }}
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </Stack>
        </div>
      </div>
    </transition>
    <div
      v-if="isOpen"
      class="size-selector__underlay"
      @click="closeSelector"
    ></div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import groupBy from "lodash.groupby";
import { alertTypes } from "@/helpers/alertTypes";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Counter from "@/components/Counter";
import Input from "@/components/Input";
import Stack from "@/components/Stack";
import StockIndicator from "@/components/StockIndicator";
import { errorAlert } from "@/helpers/errorAlert";
import { slideDown, slideUp } from "@/helpers/slide";
import { stockStatuses } from "@/helpers/stockStatuses";

export default {
  name: "ShopToSizeSelector",
  components: {
    Button,
    Counter,
    Input,
    Stack,
    StockIndicator,
  },
  props: {
    sizes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      activeUser: null,
      filteredUsersToShopTo: [],
      hoveredUser: null,
      isInformationOpen: false,
      isOpen: false,
      isSizesOpen: false,
      isUpdatingBasket: false,
      searchQuery: "",
      temporaryBasket: {},
      usersRemainingBalance: {},
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLines"]),
    ...mapGetters("shopToUser", ["usersToShopTo"]),
    ...mapGetters("products", ["currentVariant"]),
    shouldDisableButton() {
      const { temporaryBasket } = this;

      if (!this.totalQuantityOfTemporaryBasket) return true;

      const basketLinesGroupedBySku = groupBy(
        Object.values(temporaryBasket),
        "size.sku",
      );

      let hasInvalidStock = false;

      for (const sku in basketLinesGroupedBySku) {
        const values = basketLinesGroupedBySku[sku];

        const totalQuantity = values
          .map(({ quantity }) => quantity)
          .reduce((sum, quantity) => {
            return sum + quantity;
          }, 0);

        if (totalQuantity && values.length > 0 && values[0]?.size?.inventory) {
          const {
            allowNegativeStock,
            stock,
            stockStatus,
          } = values[0].size.inventory;

          if (typeof stock === "number") {
            if (!allowNegativeStock && totalQuantity > stock) {
              hasInvalidStock = true;
              break;
            }
          } else if (stockStatus === stockStatuses.OUT_OF_STOCK) {
            hasInvalidStock = true;
            break;
          }
        }
      }

      return hasInvalidStock;
    },
    totalQuantityOfTemporaryBasket() {
      if (!this.temporaryBasket) return 0;

      return Object.values(this.temporaryBasket)
        .map(({ quantity }) => quantity)
        .reduce((sum, quantity) => {
          return sum + quantity;
        }, 0);
    },
  },
  watch: {
    basketLines(newValue) {
      // Clear the basket prices if the user has no basket lines
      if (!newValue.length) {
        Object.keys(this.usersRemainingBalance).forEach(userId => {
          this.usersRemainingBalance[userId] = {
            ...this.usersRemainingBalance[userId],
            basketPrice: 0,
          };
        });
        return;
      }

      newValue.forEach(({ shopToUserId }) => {
        const userRemainingBalance = this.usersRemainingBalance[shopToUserId];

        if (userRemainingBalance) {
          this.usersRemainingBalance[shopToUserId] = {
            ...userRemainingBalance,
            basketPrice: this.getBasketPriceForUser(shopToUserId),
          };
        }
      });
    },
    searchQuery(newValue) {
      if (!newValue) {
        this.filteredUsersToShopTo = this.usersToShopTo;
        return;
      }

      const value =
        typeof newValue === "object" ? newValue.target.value : newValue;

      const options = {
        keys: [
          { name: "employeeNumber", weight: 2 },
          { name: "name", weight: 3 },
          { name: "userGroupName", weight: 1 },
        ],
      };

      const fuse = new Fuse(this.usersToShopTo, options);

      this.filteredUsersToShopTo = fuse
        .search(value)
        .map(result => result.item);
    },
    usersToShopTo(newValue) {
      this.filteredUsersToShopTo = newValue;
      newValue.forEach(user => {
        this.usersRemainingBalance[user.userId] = {
          walletBalance: user.walletBalance,
          unitPrice: this.currentVariant.minPrice.priceValue ?? 0,
          quantity: 0,
          currency: user.currency,
          basketPrice: this.getBasketPriceForUser(user.userId),
        };
      });
    },
    temporaryBasket: {
      handler: function(newValue) {
        if (this.isUpdatingBasket) {
          return;
        }
        Object.keys(newValue).forEach(userId => {
          const quantity = newValue[userId].quantity;

          this.usersRemainingBalance[userId] = {
            ...this.usersRemainingBalance[userId],
            quantity,
          };
        });
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.usersRemainingBalance = {};
  },
  methods: {
    ...mapActions("alerts", ["OPEN_ALERT"]),
    ...mapActions("basket", ["GET_BASKET", "UPDATE_BASKET"]),
    ...mapActions("shopToUser", [
      "GET_USERS_FOR_VARIANT",
      "RESET_USERS_FOR_VARIANT",
    ]),
    activateUser(id) {
      if (!id) return;

      this.activeUser = id;
    },
    addToBasket() {
      const basketLinesWithPreviousQuantity = Object.entries(
        this.temporaryBasket,
      )
        .map(entry => {
          const shopToUserId = entry[0];
          const {
            quantity,
            size: { sku },
          } = entry[1];

          const matchingBasketLine = this.basketLines.find(
            basketLine =>
              basketLine.shopToUserId === shopToUserId &&
              basketLine.sku === sku,
          );

          return {
            previousQuantity: matchingBasketLine
              ? matchingBasketLine.quantity
              : 0,
            quantity: matchingBasketLine
              ? matchingBasketLine.quantity + quantity
              : quantity,
            shopToUserId,
            sku,
          };
        })
        .filter(({ quantity }) => quantity);

      this.updateBasketLineQuantity(0, basketLinesWithPreviousQuantity);
    },
    closeInformation() {
      if (!this.activeUser) return;

      const contentElement = this.$refs.information.find(
        element => element.id === `information-${this.activeUser}`,
      );

      if (!contentElement) return;

      this.isInformationOpen = false;
      slideUp(contentElement);
    },
    closeSelector() {
      this.isOpen = false;
      this.temporaryBasket = {};
      this.RESET_USERS_FOR_VARIANT();
    },
    closeSizes() {
      if (!this.activeUser) return;

      const contentElement = this.$refs.sizes.find(
        element => element.id === `sizes-${this.activeUser}`,
      );

      if (!contentElement) return;

      this.isSizesOpen = false;
      slideUp(contentElement);
    },
    deactivateUser() {
      this.closeInformation();
      this.closeSizes();
      this.activeUser = null;
    },
    handleMouseEnter(userId) {
      if (!userId) return;

      this.hoveredUser = userId;
    },
    handleMouseLeave(userId) {
      if (!userId) return;

      this.hoveredUser = null;
    },
    openInformation() {
      if (!this.activeUser) return;

      const contentElement = this.$refs.information.find(
        element => element.id === `information-${this.activeUser}`,
      );

      if (!contentElement) return;

      this.isInformationOpen = true;
      slideDown(contentElement);
    },
    openSelector() {
      this.GET_USERS_FOR_VARIANT({ id: this.$route.query.variantId }).then(
        () => {
          this.resetTemporaryBasket();
        },
      );
      this.isOpen = true;
    },
    openSizes() {
      if (!this.activeUser) return;

      const contentElement = this.$refs.sizes.find(
        element => element.id === `sizes-${this.activeUser}`,
      );

      if (!contentElement) return;

      this.isSizesOpen = true;
      slideDown(contentElement);
    },
    resetTemporaryBasket() {
      const basket = {};

      this.usersToShopTo.forEach(user => {
        basket[user.userId] = { quantity: 0, size: this.sizes[0] };
      });

      this.temporaryBasket = basket;
    },
    selectSize({ id, size }) {
      if (!id || !size) return;

      this.temporaryBasket[id].size = size;
      this.closeSizes();
    },
    toggleInformation() {
      if (this.isInformationOpen) {
        this.closeInformation();
      } else {
        this.openInformation();
      }
    },
    toggleSizes() {
      if (this.isSizesOpen) {
        this.closeSizes();
      } else {
        this.openSizes();
      }
    },
    updateBasketLineQuantity(index, array) {
      this.isUpdatingBasket = true;

      this.UPDATE_BASKET({ ...array[index] })
        .then(() => {
          if (index < array.length - 1) {
            this.updateBasketLineQuantity(index + 1, array);
            return;
          }

          this.isUpdatingBasket = false;
          this.deactivateUser();

          this.GET_BASKET().then(() => {
            this.resetTemporaryBasket();
            this.OPEN_ALERT({
              key: "SuccessMessage.BasketUpdated",
              type: alertTypes.SUCCESS,
            });
          });
        })
        .catch(error => {
          this.isUpdatingBasket = false;
          errorAlert(error);
        });
    },
    updateQuantity({ id, value }) {
      if (!id) return;
      this.temporaryBasket[id].quantity = value || 0;
    },
    calculateRemainingBalance(userId) {
      const {
        walletBalance,
        basketPrice,
        quantity,
        unitPrice,
      } = this.usersRemainingBalance[userId];

      const selectProductPrice = (quantity ?? 0) * (unitPrice ?? 0);
      const totalPrice = (basketPrice ?? 0) + selectProductPrice;
      return +(walletBalance - totalPrice).toFixed(2) ?? 0;
    },
    hasWalletBalance(userId) {
      return this.calculateRemainingBalance(userId) > 0;
    },
    getRemainingWallet(userId) {
      const { currency } = this.usersRemainingBalance[userId];

      const remainingBalance = this.calculateRemainingBalance(userId);
      return remainingBalance > 0
        ? `${this.formatPriceCurrency(remainingBalance)} ${currency}`
        : this.getDictionaryEntry("ErrorMessage.WalletOutOfBalance");
    },
    getBasketPriceForUser(userId) {
      if (!userId) return 0;

      return this.basketLines
        .filter(basketLine => basketLine.shopToUserId === userId)
        .reduce((accumulator, currentValue) => {
          return (accumulator += currentValue.totalLinePrice.priceValue);
        }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.employee {
  border-radius: 1.75rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: relative;
  transition: background-color 300ms ease;

  &--active,
  &--hovered {
    background-color: #f4f4f4;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__block {
    flex-grow: 0;
    flex-shrink: 0;

    &:first-child {
      flex-basis: calc(50% - 1.5rem);
      overflow: hidden;
    }

    &:last-child {
      align-items: center;
      display: flex;
      flex-basis: 50%;
    }

    &--quantity {
      align-items: center;
      display: flex;
      height: 2.375rem;
      justify-content: flex-end;
    }
  }

  &__blocks {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: none;
    overflow: hidden;
  }

  &__icon {
    fill: none;
    flex: 0 0 auto;
    height: var(--size-dropdown-icon);
    margin-left: 0.5rem;
    opacity: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    transition: opacity 300ms ease, transform 300ms ease;
    width: var(--size-dropdown-icon);

    &--active {
      transform: rotate(180deg);
    }

    .employee--active & {
      opacity: 1;
    }
  }

  &__identifiers {
    color: #9a9a9a;
    font-size: 0.8125rem;
    margin-top: 0.125rem;
  }

  &__indicator {
    fill: none;
    height: 1.5rem;
    margin-right: 0.375rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    width: 1.5rem;

    &-container {
      align-items: center;
      display: flex;
      height: 2.375rem;
    }

    &-text {
      font-size: 0.875rem;
      margin-right: 1rem;
    }
  }

  &__information {
    @include reset-list();

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &-block {
      flex: 0 0 calc(50% - 1rem);
      margin-top: 1.75rem;

      &:last-child {
        margin-bottom: 1.75rem;
      }
    }

    &-title {
      font-size: 0.8125rem;
    }

    &-value {
      color: #7a7a7a;
      font-size: 0.8125rem;
      margin-top: 0.25rem;
    }
  }

  &__name {
    font-size: 1rem;
    margin: 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__summary {
    font-size: 0.875rem;
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    display: flex;
    text-align: left;
    width: 100%;
  }

  &__trigger {
    @include reset-button();

    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.employees {
  @include reset-list();
}

.indicator {
  margin-right: 0.5rem;
}

.size {
  &__action {
    @include reset-button();

    align-items: center;
    display: flex;
    padding: 0.5rem;
  }

  &__label {
    color: transparent;
    display: block;
    margin-right: 0.5rem;
  }
}

.sizes {
  font-size: 0.8125rem;
  position: relative;

  &__content {
    background-color: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 0 0 0.25rem 0.25rem;
    border-width: 0 1px 1px;
    display: none;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transform: translateY(-2px);
    z-index: 4;
  }

  &__icon {
    fill: none;
    height: var(--size-dropdown-icon);
    margin-left: 0.75rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: #b6b6b6;
    transition: transform 300ms ease;
    width: var(--size-dropdown-icon);

    &--active {
      transform: rotate(180deg);
    }
  }

  &__label {
    display: block;
    margin-right: 0.5rem;
  }

  &__list {
    @include reset-list();
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    background-color: #ffffff;
    border-radius: 0.25rem;
    border: 1px solid #dfdfdf;
    display: flex;
    padding: 0.5rem;
  }

  &__underlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
  }
}

.size-selector {
  &__box {
    background-color: var(--color-card-background);
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 4.0625rem var(--spacing-container) var(--spacing-container);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;

    @media (min-width: 48rem) {
      padding-top: 5.0625rem;
    }

    @media (min-width: 64rem) {
      height: calc(100vh - var(--size-header-fixed));
      left: calc(100vw - 2rem - 35rem);
      padding: 0;
    }

    @media (min-width: 110.625rem) {
      left: calc(100vw - (100vw - 77.75rem - 1rem));
    }

    @media (min-width: 122rem) {
      left: calc(
        77.75rem + (100vw - 120rem) / 2 - (var(--scrollbar-width) / 2)
      );
    }

    .has-service-bar & {
      @media (min-width: 64rem) {
        height: calc(
          100vh - var(--size-header-fixed) - var(--size-service-bar)
        );
      }
    }

    &-content {
      @media (min-width: 64rem) {
        width: calc(35rem + 1rem);
      }

      @media (min-width: 110.625rem) {
        width: calc(100vw - 77.75rem - 2rem);
      }

      @media (min-width: 122rem) {
        width: calc(120rem - 77.5rem - 1rem);
      }
    }
  }

  &__button-text {
    font-weight: 700;
  }

  &__close {
    @include reset-button();

    background-color: var(--color-body-background-light);
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    height: calc(var(--size-product-action-icon) * 2.6);
    width: calc(var(--size-product-action-icon) * 2.6);

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: var(--size-product-action-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      stroke-width: 2;
      width: var(--size-product-action-icon);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;

    &::before {
      background-color: var(--color-product-selected-size-background);
      border-radius: 2rem;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 300ms ease;
      z-index: -1;
    }

    &--selected {
      &::before {
        opacity: 1;
      }
    }

    &-action {
      @include reset-button();

      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      width: 100%;

      .size-selector__item--out-of-stock & {
        cursor: not-allowed;
      }
    }
  }

  &__list {
    @include reset-list();
  }

  &__selected {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    font-weight: 700;
  }

  &__size {
    font-weight: 700;

    .size-selector__item--out-of-stock & {
      font-weight: 400;
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  &__title {
    flex: 1 1 auto;
    margin-right: var(--spacing-stack);
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__underlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.wallet-error {
  color: var(--color-error);
}
</style>
