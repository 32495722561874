<template>
  <header v-if="brand" class="header">
    <Container>
      <div class="blocks">
        <div class="block block--logo">
          <router-link :to="{ name: startPageForUser }" class="logo">
            <img
              :src="brand.logo"
              :alt="`${brand.name} logo`"
              class="logo__image"
            />
          </router-link>
        </div>
        <div class="block block--actions">
          <NavigationActions
            :close-notifications="handleCloseOfNotifications"
            :close-profile="handleCloseOfProfile"
            :is-notifications-open="isNotificationsOpen"
            :is-profile-open="isProfileOpen"
            :open-catalogues="handleCataloguesClick"
            :open-notifications="handleNotificationsClick"
            :open-profile="handleProfileClick"
            :open-quick-basket="handleQuickBasketClick"
          />
        </div>
        <div class="block block--navigation">
          <Navigation :items="navigationItems" />
        </div>
        <div class="block block--search">
          <form
            id="headerSearchInput"
            class="header__search"
            autocomplete="off"
            @submit.prevent="handleSubmitOfSearch"
          >
            <Input
              :hide-label="true"
              :label="getDictionaryEntry('Common.Labels.Search')"
              :loading="isLoadingSuggestions"
              :placeholder="
                getDictionaryEntry('Common.Placeholders.HeaderSearch')
              "
              :value="searchQuery"
              name="query"
              type="search"
              @input="debouncedSearch"
            />
          </form>
          <button
            class="actions__button actions__button--menu"
            :aria-label="getDictionaryEntry('Common.Menu')"
            @click="handleMobileNavigationClick"
          >
            <svg
              class="actions__icon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path d="M3 12h18M3 6h18M3 18h18" />
            </svg>
          </button>
          <SearchSuggestions
            :close="handleCloseOfSuggestions"
            :suggestions="suggestions"
            :track-product-click="trackProductClick"
            :visible="isSuggestionsOpen"
          />
        </div>
      </div>
    </Container>
    <Catalogues
      v-if="hasAccessToProductSheet"
      :close="handleCloseOfCatalogues"
      :visible="isCataloguesOpen"
    />
    <QuickBasket
      :close="handleCloseOfQuickBasket"
      :visible="isQuickBasketOpen"
    />
    <div class="mobile-navigation">
      <Sidebar
        :title="getDictionaryEntry('Common.Navigation')"
        :right="true"
        :visible="isMobileNavigationOpen"
        @close="handleCloseOfMobileNavigation"
      >
        <template #body>
          <MobileNavigation :items="navigationItems" />
        </template>
      </Sidebar>
    </div>
  </header>
</template>

<script>
import { debounce } from "debounce";
import { mapActions, mapGetters } from "vuex";
import Catalogues from "@/components/Catalogues";
import Container from "@/components/Container";
import Input from "@/components/Input";
import MobileNavigation from "@/components/MobileNavigation";
import Navigation from "@/components/Navigation";
import NavigationActions from "@/components/NavigationActions";
import QuickBasket from "@/components/QuickBasket";
import SearchSuggestions from "@/components/SearchSuggestions";
import Sidebar from "@/components/Sidebar";
import { pushToDataLayer } from "@/helpers/tracking";
import { statuses } from "@/helpers/statuses";

export default {
  name: "Header",
  components: {
    Catalogues,
    Container,
    Input,
    MobileNavigation,
    Navigation,
    NavigationActions,
    QuickBasket,
    SearchSuggestions,
    Sidebar,
  },
  data() {
    return {
      isCataloguesOpen: false,
      isMobileNavigationOpen: false,
      isNotificationsOpen: false,
      isProfileOpen: false,
      isQuickBasketOpen: false,
      isSuggestionsOpen: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters("branding", ["brand"]),
    ...mapGetters("content", ["navigation"]),
    ...mapGetters("suggestions", ["statusOfSuggestions", "suggestedProducts"]),
    isLoadingSuggestions() {
      return (
        this.searchQuery !== "" && this.statusOfSuggestions === statuses.LOADING
      );
    },
    navigationItems() {
      if (!this.navigation) return [];

      return this.navigation.items;
    },
    startPageForUser() {
      return this.getStartPageForCurrentUser();
    },
    suggestions() {
      const { suggestedProducts } = this;

      if (!suggestedProducts || !suggestedProducts.length) return [];

      return suggestedProducts;
    },
  },
  watch: {
    $route() {
      this.searchQuery = "";
      this.UPDATE_SEARCH_QUERY({ query: "" });
      this.handleCloseOfMobileNavigation();
      this.handleCloseOfNotifications();
      this.handleCloseOfQuickBasket();
      this.handleCloseOfSuggestions();
    },
  },
  created() {
    const { search } = this;

    this.debouncedSearch = debounce(function(query) {
      search(query);
    }, 500);
  },
  methods: {
    ...mapActions("suggestions", [
      "GET_SUGGESTIONS_FOR_PRODUCTS",
      "RESET_SUGGESTIONS",
      "UPDATE_SEARCH_QUERY",
    ]),
    getSuggestions() {
      this.GET_SUGGESTIONS_FOR_PRODUCTS({
        searchQuery: this.searchQuery,
      });
    },
    handleCataloguesClick() {
      this.isCataloguesOpen = true;
    },
    handleCloseOfCatalogues() {
      this.isCataloguesOpen = false;
    },
    handleCloseOfMobileNavigation() {
      this.isMobileNavigationOpen = false;
    },
    handleCloseOfNotifications() {
      this.isNotificationsOpen = false;
    },
    handleCloseOfProfile() {
      this.isProfileOpen = false;
    },
    handleCloseOfQuickBasket() {
      this.isQuickBasketOpen = false;
    },
    handleCloseOfSuggestions() {
      this.isSuggestionsOpen = false;
      this.searchQuery = "";
      this.RESET_SUGGESTIONS();
    },
    handleMobileNavigationClick() {
      this.isMobileNavigationOpen = true;
    },
    handleNotificationsClick() {
      this.isNotificationsOpen = true;
    },
    handleProfileClick() {
      this.isProfileOpen = true;
    },
    handleQuickBasketClick() {
      this.isQuickBasketOpen = true;
    },
    handleSubmitOfSearch(event) {
      this.debouncedSearch.clear();
      this.search(event.target.elements.query.value);
    },
    search(query) {
      const value = typeof query === "object" ? query.target.value : query;
      this.searchQuery = value.trim();

      this.isSuggestionsOpen = !!this.searchQuery;

      //Closing the suggestions/clearing the query when not on the products
      //route can safely be ignored in order to save a request.
      if (!this.searchQuery) return;
      this.getSuggestions();
    },
    trackProductClick(product, index) {
      const productClick = {
        event: "productClick",
        ecommerce: {
          click: {
            actionField: { list: "Search suggestions" },
            products: [
              {
                name: product.productName,
                id: product.id,
                price: product.minPrice ? product.minPrice.price : "",
                brand: product.brand,
                variant: product.variantName,
                position: index + 1,
              },
            ],
          },
        },
      };

      pushToDataLayer(productClick);
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-xsmall: 23.4375rem;
$breakpoint-small: 40rem;
$breakpoint-large: 64rem;
$breakpoint-xlarge: 80rem;

.actions {
  &__button {
    @include reset-button;

    display: block;
    padding: var(--spacing-header-actions);
    position: relative;
    text-align: center;
    text-decoration: none;

    &--menu {
      margin-left: calc(var(--spacing-container) * 0.75);

      @media (min-width: $breakpoint-large) {
        display: none;
      }
    }
  }

  &__icon {
    fill: none;
    height: var(--size-header-action-icon);
    opacity: 0.85;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    width: var(--size-header-action-icon);

    * {
      stroke: var(--color-header-text);
    }
  }
}

.block {
  &--actions {
    display: flex;
    justify-content: flex-end;
    margin-left: var(--spacing-container);

    @media (min-width: $breakpoint-large) {
      flex: 0 0 auto;
      margin-left: calc(var(--spacing-container) * 1.25);
      order: 3;
    }
  }

  &--logo {
    flex: 0 1 auto;

    @media (min-width: $breakpoint-xsmall) {
      flex: 0 0 40%;
    }

    @media (min-width: $breakpoint-small) {
      margin-right: 2.5rem;
    }

    @media (min-width: $breakpoint-large) {
      flex: 0 0 auto;
      order: 0;
    }

    @media (min-width: $breakpoint-xlarge) {
      margin-right: 4rem;
    }
  }

  &--navigation {
    flex-basis: calc(60% - var(--spacing-container));
    flex-grow: 0;
    flex-shrink: 0;

    @media (min-width: $breakpoint-large) {
      flex: 1 1 auto;
      margin-right: 2.5rem;
      min-width: 0;
      order: 1;
    }
  }

  &--search {
    align-items: center;
    background-color: var(--color-header-background);
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    margin-top: calc(var(--spacing-container) * 0.5);
    position: relative;

    @media (min-width: $breakpoint-large) {
      display: block;
      flex-basis: auto;
      margin: 0;
      order: 2;
    }

    &::before {
      @media (min-width: $breakpoint-large) {
        background: linear-gradient(
          to left,
          rgba(var(--color-header-background-rgb), 1) 25%,
          rgba(var(--color-header-background-rgb), 0.75) 75%,
          rgba(var(--color-header-background-rgb), 0) 100%
        );
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 2.5rem;
      }
    }
  }
}

.blocks {
  align-items: center;
  color: var(--color-header-text);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (min-width: $breakpoint-large) {
    flex-flow: row;
    justify-content: center;
  }
}

.header {
  background-color: var(--color-header-background);
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.043);
  flex: 0 0 auto;
  font-size: 0.875rem;
  padding: calc(var(--spacing-container) * 0.5) 0 var(--spacing-container);

  @media (min-width: $breakpoint-large) {
    padding: calc(var(--spacing-container) * 1.407) 0;
  }

  @media print {
    display: none;
  }

  .has-service-bar & {
    @media (min-width: $breakpoint-large) {
      top: var(--size-service-bar);
    }
  }

  &__search {
    flex: 1 1 auto;
  }
}

.logo {
  height: var(--size-logo-small);

  @media (min-width: $breakpoint-xsmall) {
    height: var(--size-logo);
  }

  @media (min-width: $breakpoint-large) {
    height: var(--size-logo-large);
  }

  &,
  &__image {
    display: block;
  }

  &__image {
    cursor: pointer;
    display: block;
    height: 100%;
    object-fit: contain;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &[src*=".svg"] {
        width: 100%;
      }
    }
  }
}

.shop-as {
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-end;
  margin-bottom: calc(var(--spacing-stack) * 0.5);

  @media (min-width: $breakpoint-large) {
    margin-bottom: 0;
    transform: translateY(-67.5%);
  }

  &__block {
    align-items: center;
    display: flex;
  }

  &__text {
    margin-right: calc(var(--spacing-stack) * 0.5);
  }
}
</style>
